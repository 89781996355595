.spinner-outer {
    width: 100%;
    background: rgba(0,0,0,.075);

    position: absolute;
    height: 100%;
    pointer-events: none; }

.spinner-inner {
    position: absolute;
    top: 50%;
    left: 50%; }

.spinner-container-active {
    opacity: .5;
    position: relative; }

.spinner-container-inactive {
    position: relative; }

.weekend {
    background: #f4f4f4;
    color: #888; }

.timesheet-table {
    border-collapse: collapse;
    td {
        padding: 0;
        margin: 0; } }

.hours {
    background-image: none !important;
    padding-right: 0 !important; }

.login-status {
    padding: 0px;
    margin: 0px;
    background-color: white;
    border: 1px solid lightgray;
    line-height: 9pt;
    font-size: 9pt;
    font-weight: 600;
    text-align: right; }

.logged-out-status {
    padding: 10px; }

.read-only-label {
    font-weight: 600 !important; }

.error-select {
    border: 2px solid purple; }

.carousel-black {
    color: #000; }

.password-letters {
    font-size: 36px;
    background: lightgray;
    padding: 16px;
    font-family: "Courier New";
    font-weight: bolder; }

@media (max-width: 1339px) {
  .timesheet-comments {
    width: 2%; } }

@media (max-width: 1339px) {
  .timesheet-work-item {
    width: 18%; } }

@media (max-width: 1339px) {
  .timesheet-time {
    width: 10%; } }

@media (min-width: 1340px) {
  .timesheet-comments {
    width: 22%; } }

@media (min-width: 1340px) {
 .timesheet-work-item {
    width: 30%; } }

@media (min-width: 1340px) {
  .timesheet-time {
    width: 6%; } }

@media (max-width: 1339px) {
  .hide-on-smaller {
    display: none; } }

@media (min-width: 1340px) {
  .hide-on-larger {
    display: none; } }
