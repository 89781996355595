@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_gradients.scss";
@import "~react-vis/dist/style";

@import "./spinner.sass";

$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1340px );

$container-max-widths: ( sm: 540px, md: 900px, lg: 960px, xl: 1300px );

$font-size-base:            0.8rem;
$line-height-base:          1.0;

$text-muted:                $gray-500;

$green:                     #317f20;
$blue:                      #186056;
$orange:                    #c07e4e;
$red:                       #932530;
$info:                      #05443b;
$light-gray:                #eee;
$dark-gray:                 #b1b1b1;

$blockRed:                  #dc3a49;
$theme-colors:              ( danger: $red, primary: $blue, success: $green, warning: $orange, info: $info );

$table-hover-bg:            rgba($black, .005);

$link-color:                theme-color("primary");

$btn-padding-y-sm:          0.25rem;
$btn-padding-x-sm:          0.35rem;

$font-size-sm:              ($font-size-base * .875);
$btn-font-size-sm:          $font-size-sm;

$dropdown-link-active-bg:   theme-color("primary");

.cmp-nav {
    @include gradient-x($gray-300, $white, 50%, 100%);

    .navbar-brand {
        color: $white !important; } }

html {
    min-height: 100%; }

body {
    background-color: $gray-100 !important; }

th {
    vertical-align: middle !important; }

.nav-item {
    cursor: pointer; }

.comment-box {
    text-align: center; }

@mixin dropZone {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    height: 100px;
    text-align: center;
    border-radius: 10px;
    line-height: 26px;
    border: 2px dashed $info !important; }

@mixin countLink($color) {
    text-decoration: none;
    color: $color;
    &:hover {
        color: scale-color($color, $lightness: -40%);
        text-decoration: none; } }

.dropZone {
    @include dropZone; }

.dropZoneActive {
    @include dropZone;
    background-color: $light-gray; }

.dropZoneThumbnail {
    width: 100%; }

.dropZoneReport {
    padding: 10px; }

.series-secondary {
    stroke: $green !important; }

.series-tertiary {
    stroke: $orange !important; }

.count-warning a {
    @include countLink($orange); }

.count-success a {
    @include countLink($green); }

.count-info a {
    @include countLink($info); }

.count-danger a {
    @include countLink($red); }

.big-count {
    font-size: -webkit-xxx-large; }

.portrait-small {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid $dark-gray; }

.login-bar {
    position: absolute;
    right: 3px;
    top: 5px;
    z-index: 999; }

.editor-span {
    padding-left: 10px; }

.page-footer {
    border-top: 0px !important;
    padding: 5px !important;
    text-align: right; }

.system-logo {
    margin-right: 0px !important; }

.no-gutters-custom {
	padding-right: 0 !important;
	padding-left: 0 !important; }

.custom-progress {
    height: 2rem !important; }

.custom-progress-bar {
    align-items: center !important; }

.original-fee-color {
    background-color: #4472c4 !important; }

.subcontractors-original-fee-color {
    background-color: #9cadda !important; }

.variations-original-fee-color {
    background-color: #70ad47 !important; }

.variations-subcontractors-original-fee-color {
    background-color: #a0cb8c !important; }

.invoiced-to-date-color {
    background-color: #ffd78b !important; }

.paid-to-date-color {
    background-color: #ffc000 !important; }

.custom-progress-bar-padding {
    padding: 0 2rem !important; }

.project-financial-legend {
    padding: 1rem 0;
    list-style: none;
    font-size: 0.8rem;

    .legend {
        line-height: 3;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align:: center;
        align-items: center;

        span {
            width: 3rem;
            height: 2rem;
            border-radius: 3px;
            content: "";
            display: inline-block;
            vertical-align: middle;
            -webkit-margin-end: .5rem;
            margin-inline-end: .5rem;
            border-width: 2px;
            border-style: solid; } } }

.no-gutters-custom {
    .fluid-container-wrapper {
        background-image: linear-gradient(to right, #dee2e6 50%, #fff 100%)  !important;
        background-repeat: repeat-x  !important; }
    .cmp-nav {
        width: 1270px !important;
        margin: 0 auto !important;
        background-color: transparent !important;
        background-image: none !important; }
    .login-status {
        .login-status-wrapper {
            width: 1270px !important;
            margin: 0 auto !important; } }
    .card-title {
        width: 1270px !important;
        margin: 0 auto 0.75rem auto !important;
        padding: 0 1.25rem !important; }
    .card-subtitle-wrapper {
        width: 1270px !important;
        margin: 0 auto 0.75rem auto !important;
        padding: 0 1.25rem !important; } }

.modal-xxl {
    max-width: calc(100vw - 1.75rem) !important; }

@media (min-width: 1340px) {
  .container {
    max-width: 1400px !important; } }

@import '~bootstrap/scss/bootstrap.scss';

/**apexchart css 25-03-2022 **/
.apexcharts-legend-series {
    display: flex;
    align-items: center; }

.alert button {
    padding: 5px 10px !important; }
